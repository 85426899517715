import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/installservicerepair/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";

import img1 from "../../images/installservicerepair/1.jpg";
import img2 from "../../images/installservicerepair/2.jpg";
import img3 from "../../images/installservicerepair/3.jpg";
import img4 from "../../images/installservicerepair/4.jpg";
import img5 from "../../images/installservicerepair/5.jpg";
import img6 from "../../images/installservicerepair/6.jpg";
import img7 from "../../images/installservicerepair/7.jpg";
import img8 from "../../images/installservicerepair/8.jpg";
import img9 from "../../images/installservicerepair/9.jpg";
import img10 from "../../images/installservicerepair/10.jpg";
import img11 from "../../images/installservicerepair/11.jpg";
import img12 from "../../images/installservicerepair/12.jpg";
import img13 from "../../images/installservicerepair/13.jpg";
import img14 from "../../images/installservicerepair/14.jpg";
import img15 from "../../images/installservicerepair/15.jpg";
import img16 from "../../images/installservicerepair/16.jpg";
import img17 from "../../images/installservicerepair/17.jpg";
const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
    {
        original: img5,
    },
    {
        original: img6,
    },
    {
        original: img7,
    },
    {
        original: img8,
    },
    {
        original: img9,
    },
    {
        original: img10,
    },
    {
        original: img11,
    },
    {
        original: img12,
    },
    {
        original: img13,
    },
    {
        original: img14,
    },
    {
        original: img15,
    },
    {
        original: img16,
    },
    {
        original: img17,
    },
];
const InstallServiceRepair = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Installation, Service and Repair"}
            navSize={"h-110 xs:h-96"}
            titleStyles={"xl:mt-14 lg:mt-24 md:mt-16 sm:mt-16 mt-16"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Install, Service &amp; Repair
                </title>
                <meta
                    name="description"
                    content="Installation, service and repair of power generation infrastructure. 24-7 call out service and preventative maintenance schedules."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-lightgray"}>
                <p
                    className={
                        "text-center font-exo2 leading-8 text-xl py-5"
                    }
                >
                    E-P-G Solutions has expertise in installing, servicing and repair of
                    power generation infrastructure across the Territory.
                </p>
            </SectionLayout>
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white py-8"}>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We know that interruptions to power and water services can be a major
                        problem, so we provide a{" "}
                        <span className={"font-bold"}>24-7 callout service.</span> Just give
                        us a call and we can provide over-the-phone interim advice while we
                        get on site. Our field services team will be there as soon as we can
                        to get the problem.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        When we design and construct for you, we can provide on-site
                        installation so you can be assured that everything is done properly
                        and that any problems that have arisen during transport or with the
                        site itself are picked up and dealt with straightaway.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        To ensure the longevity of your infrastructure and maintain
                        reliability, we recommend a preventative maintenance schedule for all
                        your assets. Our team come to you, undertake any necessary repairs and
                        identify any potential problems that may need attention down the road.
                    </p>
                </div>
                <div className={"bg-epg-white pt-5"}>
                    <Gallery images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default InstallServiceRepair;
